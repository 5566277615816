import { AgentType } from "../../cmc-adjustment/types/AgentType";
import { AdjustmentHistoryCompanyPlantsByIdType, AdjustmentHistoryDosageLocationType } from "../types";

/**
 * Type para item da listagem
 *
 * @typedef {Object} AdjustmentHistoryListType
 * @property {number} id
 * @property {number} action
 * @property {number} type
 * @property {number} adjust
 * @property {string} title
 * @property {?number} binder
 * @property {?number} water
 * @property {?number} content_mortar
 * @property {?number} total_air
 * @property {?Array<AgentType>} agent
 * @property {?string} observation_mcc
 * @property {?number} content
 * @property {?number} content_min
 * @property {?number} content_max
 * @property {?string} observation_content
 * @property {?Array<AdjustmentHistoryDosageLocationType>} dosage_locations
 * @property {boolean} has_company_plants
 * @property {?AdjustmentHistoryCompanyPlantsByIdType} company_plants
 * @property {string} created_by_user_name
 * @property {string} created_at
 */
export const AdjustmentHistoryListType = {};

/**
 * Inicializar AdjustmentHistoryListType
 *
 * @returns {AdjustmentHistoryListType}
 */
export const initAdjustmentHistoryListType = () => ({
  id: 0,
  action: 0,
  type: 0,
  adjust: 0,
  title: "",
  binder: null,
  water: null,
  content_mortar: null,
  total_air: null,
  agent: null,
  observation_mcc: null,
  content: null,
  content_min: null,
  content_max: null,
  observation_content: null,
  dosage_locations: null,
  has_company_plants: null,
  company_plants: null,
  created_by_user_name: "",
  created_at: "",
});
